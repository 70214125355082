/********************************************
 Theme Name: Theme 39
 Version: 1.0
 Author: Hardeep Kumar
Last Modified: April 12, 2019
 ********************************************/
 @media only screen and (min-width: 1280px) {
	.co_commn_detail_wrap {
		clear: both;
		width: 100%;
		display: grid;
		grid-template-columns: min(30%, 300px) auto;
	 
	}

  .co_commn_detail_wrap.single_col {
		-ms-grid-columns:auto;
		grid-template-columns:auto;	 
	}
	.prop.co_commn{
		margin-left:25px !important;
		display: block;
		float: unset !important;
		right: unset !important;
	}

	.search-results .prop.co_commn.commn_detail{
		display: block !important	;
	}
}
@media only screen and (max-width: 1280px) {
  .searchform .search_built select:last-of-type {
    margin-left: 0;
  }

  .searchform li input[type="text"] {
    margin-left: 0;
    width: 201px;
  }

  .searchform .searchprice input[type="text"] {
    width: 84px;
  }
}

@media only screen and (max-width: 1177px) {
  .logo .agentheader img {
    float: left;
  }
  .logo ol {
    float: left;
    width: 54%;
    margin-left: 15px !important;
  }

  .main_menu li {
    font-size: 1.6rem;
    padding: 0 14px;
  }

  .mortgage_calc {
    width: 98%;
  }

  .action-strip .menu_login {
    width: 90%;
    margin-left: 50px;
  }

  .card-view .alt_property_container {
    width: 30%;
  }

  .card-view .alt_propimage,
  .card-view .alt_propimage img,
  .search_results.card-view .alt_propimage,
  .search_results.card-view .alt_propimage img {
    width: 100%;
  }

  .search_results.card-view .alt_property_container {
    margin: 20px 10px 50px 10px;
  }

  .search_results.row-view .right_side {
    width: 69%;
  }

  /* Other pages */
  .centerbar,
  .article {
    width: 68%;
    margin-left: 0;
  }
  .rightbar {
    width: auto;
  }
  aside {
    width: 29%;
  }

  /* Result page pagination */
  #pagination_container form select {
    width: 72px !important;
  }

  #lower-pagination_container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .results_sort {
    float: none;
    margin: 10px auto 20px;
    text-align: center;
  }

  .numeric_pagination {
    justify-content: center;
    margin: 10px 0 30px;
  }

  /* Detail Page */
  .map_property .property_gallery {
    width: 100%;
    float: none;
    margin: 0 auto 20px;
    text-align: center;
    height: auto;
  }
  .property_address,
  .property_price {
    text-align: center;
  }
  .property_apply {
    text-align: center;
    margin: 0 auto;
    height: auto;
    max-height: 100%;
  }

  .maps_block {
    width: 100%;
    float: none;
  }
  .map_search {
    width: 48%;
  }
  .streetview {
    width: 48%;
    padding-left: 2%;
  }

  .property_img_gallery {
    display: none;
  }

  .property-detail-sec .detailform {
    width: 28%;
  }

  #detailprop {
    width: 72%;
  }

  #detailprop ul,
  .detail_comment {
    width: 28%;
    margin-left: 2%;
  }
  .buyer_agent_comm_block {
    margin-left: 2%;
  }
  .detail_comment {
    width: 30%;
  }

  .property-detail-sec {
    margin-top: 10px;
  }

  .detailfeatures .detailfeatures {
    margin: 5px 3% 15px 0px;
  }

  .detailform li:nth-child(2),
  .detailform li:nth-child(3) {
    width: 300px;
  }

  .centerbar ol.alt_details {
    margin-left: 0;
  }

  .bottom-strip .caroufredsel_wrapper {
    width: 94% !important;
    margin: 13px 30px !important;
  }

  #sidesearch select,
  .refine_search select,
  .refine_search input[type="text"] {
    width: 100%;
  }

  .left-section.header-center li a {
    padding: 0 8px;
  }

  .left-section.header-center li {
    font-size: 1.6rem;
  }
  .listing_more_details_wrap {
    width: 70%;
  }
}

@media only screen and (max-width: 980px) {
  .card-view .alt_property_container {
    width: 46%;
  }

  .main_menu li {
    font-size: 1.3rem;
    padding: 0 8px;
  }

  .main_menu li.close_icon i {
    font-size: 2.8rem;
  }

  .action-strip a {
    font-size: 3.8rem;
  }

  .action-strip span {
    font-size: 1.7rem;
    margin-left: 58px;
  }

  .action-strip .menu_login {
    width: 94%;
    margin-left: 20px;
  }

  /** pre header **/
  .logo {
    width: 155px;
  }
  .logo .tagline {
    font-size: 1.4rem;
  }
  .left-section.header-center {
    padding-left: 10px;
    text-align: center;
    width: calc(100% - 585px);
  }

  .left-section.header-center li {
    margin-right: 0;
    margin-bottom: 15px;
  }

  /** Other pages **/
  .centerbar,
  .article,
  .rightbar,
  aside {
    width: 100%;
    border: none;
    padding-left: 0;
  }

  footer nav {
    margin: 0px 0px 20px;
  }
  .footercopy {
    margin-left: 0px;
  }
  .brokeridxlink {
    margin-right: 0px;
  }

  .refine_search,
  #sidesearch {
    padding: 0 5px 20px;
  }
  footer .subdivisionlist {
    overflow: hidden;
    margin: 0px 0 20px;
  }

  footer {
    padding-left: 5px;
    padding-right: 5px;
  }

  #lower-pagination_container {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 768px) {
  /* slick nav */
  .slicknav_menu {
    display: block;
  }

  .header {
    padding-bottom: 10px;
  }

  header.inner-page {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }

  #menu_section .menu {
    display: none;
  }

  #menu_section {
    height: auto;
    line-height: 24px;
  }

  /* header */
  .logo {
    padding-top: 15px;
    width: 100%;
    overflow: hidden;
    float: none;
  }

  #pre-header {
    text-align: center;
  }

  .logo.inner-page-logo {
    width: 100%;
  }

  .social-media-links {
    float: none;
  }

  .social-media-links li {
    float: none;
    display: inline-block;
    line-height: 0;
  }

  .left-section.header-center {
    width: 100%;
  }

  .left-section.header-center li {
    margin-bottom: 0;
    margin-right: 15px;
    display: inline-block;
  }

  .home-links.social-media-links {
    padding: 10px 20px;
    text-align: center;
    float: none;
    width: 100%;
  }

  .logo .agentheader img {
    max-width: 150px;
  }

  .container,
  #breadcrumbs {
    width: 98%;
    padding: 0px 1%;
    box-sizing: border-box;
  }

  .main_menu {
    height: auto;
    line-height: 28px;
    padding: 20px 10px;
  }

  #banner {
    height: auto;
  }

  .left-section {
    float: none;
    position: static;
    margin: 10px 10px;
    width: calc(100% - 20px);
    box-sizing: border-box;
    padding: 0;
  }

  .left-section li {
    display: block;
    margin-right: 0px;
    margin-bottom: 5px;
  }

  .testimonial-main .testimonial-pg,
  #foo2_pag {
    margin-top: 0;
  }

  .testimonial-main {
    max-width: 96%;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    float: none;
  }

  .action-strip a {
    font-size: 2.5rem;
  }

  .action-strip span {
    font-size: 1.2rem;
    margin-left: 45px;
  }

  .marketing-form,
  #header-btm #sidesearch {
    float: none;
    width: 97%;
    margin: 20px 1.5%;
  }

  .footercopy {
    width: 100%;
    margin-left: 0;
  }

  footer {
    padding: 19px 10px;
  }

  footer .menu ul {
    float: none;
    width: 100%;
    margin-right: 0;
  }

  footer .menu li {
    float: left;
    width: 46%;
    margin-right: 4%;
  }

  footer .menu-column.column-0 li:nth-child(2n + 2),
  footer .menu-column.column-1 li:nth-child(2n + 1) {
    margin-right: 0;
  }

  .search_results.card-view .alt_property_container,
  .card-view .alt_property_container {
    width: 45%;
    margin: 20px 10px 50px;
  }

  .card-view .alt_property .right_side {
    height: 195px;
  }

  .card-view .alt_property_container:hover p.description.alt_description {
    max-height: 155px;
    min-height: 155px;
  }

  .card-view .alt_address {
    padding: 0px 5px 0px 25px;
    background: url(../images/icon-location.png) no-repeat 10px 10px;
    font-size: 1.2rem;
  }

  .prop {
    margin-right: 10px;
  }

  /* Detail Page */
  .propholder {
    padding: 0px;
  }

  .maps_offer {
    width: 100%;
  }

  .detail-submit-offer {
    width: 100%;
    margin-bottom: 50px;
  }

  /* Other pages */
  #breadcrumbs {
    display: none;
  }
  .centerbar {
    width: 100%;
    border-right: none;
    padding-right: 0px;
    margin-top: 20px;
  }

  .featuredprop {
    margin: 0px 20px 22px;
    display: inline-block;
  }

  li.ss_beds {
    overflow: hidden;
    width: 100%;
  }

  /* Blog */
  .blog-title {
    width: 100%;
  }

  #commentform [type="submit"] {
    margin-left: 0px;
  }

  /* search */
  .searchform ol {
    width: 92%;
  }
  .searchform .the_search {
    margin-right: 20px;
  }
  .the_search.lower_bttn {
    margin-top: 5px;
  }

  .searchform li input[type="text"] {
    margin-left: 3px;
  }

  .searchform form li b {
    width: 180px !important;
  }

  .searchform .searchprice input[type="text"] {
    width: 80px;
  }

  .searchform .search_built select:last-of-type {
    margin-left: 183px;
  }

  /* Promo page */
  .promo_text {
    margin: 0px 10px;
  }
  .promo_form {
    margin: 0px 10px;
  }

  /* form */
  .mtg_form ol,
  .contact_form ol {
    margin-left: 0px !important;
  }
  form li b {
    width: 120px;
  }
  .centerbar input.submit,
  .centerbar .detailform input.submit {
    margin-left: 0px;
  }

  /*** popups ***/
  #register-popup {
    width: 98%;
    left: auto;
    margin: 0 auto;
    position: relative;
    height: 465px;
    overflow: scroll;
  }

  .register_fields {
    width: 100%;
    float: none;
  }

  .popupform {
    padding: 20px;
  }

  .popupform input[type="text"],
  .popupform input[type="password"],
  .popupform input[type="email"],
  .popupform input[type="tel"],
  .popupform input[type="submit"] {
    width: 100%;
  }

  #register-popup {
    height: 930px;
  }

  .register_help {
    float: none;
    width: 100%;
    margin-top: 30px;
  }

  /* Detail Page */
  .maps_block {
    height: auto;
    overflow: hidden;
    margin-top: 30px;
  }
  .map_search {
    width: 100%;
  }
  .streetview {
    width: 100%;
    padding-left: 0;
    border-left: none;
    border-top: 1px solid var(--border);
    padding-top: 10px;
    margin-top: 10px;
  }

  #detailprop ul,
  .buyer_agent_comm_block {
    width: 100%;
    margin-right: 0;
  }
  .buyer_agent_comm_block {
    margin-left: 0;
  }
  .detail_comment {
    width: 100%;
    padding-top: 0;
  }

  .detailfeatures .detailfeatures:nth-child(3n + 3) {
    margin-right: 3%;
  }
  .detailform li:nth-child(2),
  .detailform li:nth-child(3) {
    width: 50%;
  }
  .detailform li:first-child input.thename,
  .detailform li:first-child input {
    width: 43% !important;
  }
  .information-popup li input[type="text"],
  .information-popup li input[type="email"],
  .detailform li input[type="text"],
  .detailform li input[type="email"],
  .information-popup li textarea,
  .detailform textarea {
    width: 100% !important;
    max-width: 100%;
    min-width: 100%;
  }
  .apply_link span {
    line-height: 15px;
  }

  .refine_search select,
  .refine_search input[type="text"] {
    width: 190px;
    float: left;
  }

  .refine_search span.refine-price-from,
  .refine_search span.refine-price-to {
    max-width: 92px;
  }

  .refine_search li.refine_price select {
    margin-right: 5px;
  }

  #sidesearch b {
    width: 100%;
  }

  .saved_istings_container .right_side,
  .lplist .right_side {
    margin-left: 0;
  }

  .drop_cap {
    width: 100% !important;
    margin: 5px 0 !important;
    float: none !important;
  }

  .profile_firstname {
    margin-bottom: 5px;
  }

  .customer_search_form .submitbtn {
    position: static;
  }

  .account_settings .search_price input[type="text"] {
    width: 200px;
    display: block;
    margin-left: 0;
  }

  .account_settings .search_built select:last-of-type {
    margin-left: 153px;
  }

  .searchform .the_search {
    margin-bottom: 20px;
  }

  .thelastname {
    margin-top: 5px;
  }

  .searchitems {
    margin-top: 20px;
  }

  #detailprop {
    width: 100%;
  }

  #detailprop ul,
  .detail_comment {
    width: 100%;
    margin-left: 0;
    padding: 0px;
    box-sizing: border-box;
  }
  .property-detail-sec .detailform {
    width: 100%;
    float: none;
    padding-bottom: 10px;
    margin-top: 15px;
  }

  .detailform ul {
    width: 98%;
  }

  .property-detail-sec {
    margin-top: 0;
    background: transparent;
  }

  footer .menu li:nth-child(3n + 3),
  footer .subdivisionlist li:nth-child(3n + 3) {
    margin-right: 4%;
  }

  footer .subdivisionlist li {
    width: 46%;
    margin: 10px 4% 10px 0px;
  }

  .social-media-links ul {
    padding: 0;
  }

  .property-sec,
  .apply-sec {
    width: 100%;
    float: none;
    text-align: center;
  }

  .apply-sec {
    border-left: 0;
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid var(--content-light-bg);
  }
  .main-image {
    height: auto;
  }

  .main-image #next,
  .main-image #prev {
    top: calc(100% - 450px);
  }

  #detailprop li{
    padding: 0 15px;
  }
  #detailprop .buyer_agent_comm_block li{
    padding: 0;
  }

  #detailform.scrolled {
    position: relative;
    right: 0px !important;
  }
  .detail_features_container {
    width: auto;
  }
  .similar-properties-wrap .next-similar {
    left: calc(100% - 45px);
  }
  .listing_more_details_wrap {
    width: 100%;
  }

  .homepage-banner-wrap .banner-heading h1 {
    font-size: 4.5rem;
  }
  .homepage-banner-wrap .banner-heading p {
    font-size: 1.8rem;
  }
  .homepage-banner-wrap .banner-menu {
    max-width: 500px;
  }
  .homepage-banner-wrap .banner-menu-button {
    width: 28%;
    padding: 11px;
  }
  .alt_details {
    width: 65%;
  }

  .property-payment-calculator .field_sec div.width-50 {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  /* Result page minimalist view */
  .alt_propimage {
    width: 100%;
  }

  .alt_title {
    padding-right: 0;
  }

  .search_results.row-view .right_side {
    margin-left: 0;
    width: 100%;
  }

  .row-view .alt_details {
    line-height: 22px;
  }
}

@media only screen and (max-width: 480px) {
  .logo .agentheader img {
    max-width: 125px;
  }

  .card-view .alt_property_container,
  .search_results.card-view .alt_property_container {
    width: 94%;
  }

  .card-view .alt_property_container:hover p.description.alt_description {
    max-height: 120px;
    min-height: 120px;
  }

  .card-view .alt_property .right_side {
    height: 165px;
  }

  .calc_block,
  .signup_block {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    margin-bottom: 5px;
  }

  table {
    width: 100% !important;
  }

  .action-strip .menu_login {
    margin-left: 10px;
  }

  #login_form {
    width: 100%;
    box-sizing: border-box;
  }

  .agentlist ul {
    text-align: center;
  }

  /* Agents Page */
  .agentpage ul {
    width: 100%;
    overflow: hidden;
    margin-left: 0px;
  }

  .agent_image {
    width: 100%;
    overflow: hidden;
    margin-bottom: 5px;
  }

  /* Promo page */
  .promo_form li:first-child {
    margin-bottom: 0px;
  }

  /* form */
  .contact_form ol {
    margin-left: 0px;
  }
  .centerbar form li b {
    display: block;
  }
  input.thefirstname,
  input.thelastnamee,
  input.thename,
  .contact_form_thename {
    display: block;
    width: 190px !important;
  }

  .contact_form_thename {
    margin: 0 0 5px;
  }

  input.thefirstname,
  input.thename {
    margin-bottom: 10px;
  }

  /* Result page */
  .propimage,
  .prop,
  .prop.co_commn {
		float: none;
		width: 100%;
		margin: 0;
	}

  .prop.col2 {
    margin-bottom: 20px;
  }

  .results_sort,
  #pagination_container {
    float: none;
    margin: 10px 0 20px;
  }

  #pagination_container {
    line-height: 40px;
    margin: 10px 0;
  }

  .sd_addressbar {
    height: auto;
    line-height: inherit;
  }
  .fplist.agentlist {
    margin-bottom: 0;
  }

  .centerbar ol,
  .centerbar ul {
    margin-left: 10px;
  }

  /* Result page minimalist view */
  .alt_propimage {
    width: 100%;
  }

  .alt_title {
    padding-right: 0;
  }

  .search_results.row-view .right_side {
    margin-left: 0;
    width: 100%;
  }

  .row-view .alt_details {
    line-height: 22px;
  }

  .centerbar ol.alt_details,
  .centerbar .agentpage ul,
  .form_container ol,
  .form_container ul,
  .account_settings ol {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }

  .customer_search_form .submitbtn {
    bottom: 40px;
  }

  /* forms */
  .thefirstname,
  .thelastnamee,
  .contactform_thename,
  .contactform_thename {
    margin-left: 0px;
  }

  .widget,
  .right_side {
    width: 100%;
  }

  /* popups */
  #login-popup,
  #recover-popup {
    width: 310px;
    left: 50%;
    margin-left: -155px;
  }

  .popupform {
    padding: 19px 10px;
  }

  .closePopup {
    right: 10px;
    top: 22px;
    padding: 5px;
  }

  /* Detail Page */
  .property_apply {
    width: 100%;
  }
  .bidx_main_image {
    width: 100%;
    box-sizing: border-box;
  }

  .detailfeatures .detailfeatures {
    width: 100%;
    margin-right: 0px;
  }
  .profile_firstname,
  .profile_lastname,
  .searchprice input {
    display: block;
    width: 200px !important;
  }

  div.search_links,
  .customer_search_form .submitbtn {
    position: static;
  }

  .customer_search_form label {
    display: block;
  }

  .apply_link {
    padding: 7px 5px;
  }

  /** popup gallery */
  .thumbs li {
    margin: 5px 6px;
  }

  .thumbs li img {
    width: 40px;
    height: 40px;
  }

  .bottom-strip .caroufredsel_wrapper {
    width: 90% !important;
    margin: 13px 20px !important;
  }

  footer nav {
    margin: 0px 0 20px;
  }

  .profile-display.customer-delete,
  .logo .agentheader img,
  .profile_photo,
  .widget_photo_container,
  .logo {
    float: none;
  }
  .logo ol {
    float: none;
    display: block;
    margin-left: 0 !important;
    width: 85%;
  }

  /*.testimonial-main {
	  width: 90%;
	  margin: 50px auto 20px;
	}
	*/
  .left-section {
    width: 90%;
  }

  .calc_block,
  .signup_block {
    float: none;
  }

  .mortgage_calc,
  .action-strip .menu_login {
    text-align: center;
    float: none;
    margin-left: 0;
    width: 100%;
  }

  .action-strip span {
    margin-left: 0;
  }

  .testimonial-list-container .testimonial {
    max-width: 260px;
    width: 100%;
  }

  .pagebody ul,
  .pagebody ol {
    padding-left: 10px;
    text-align: left;
  }

  .agentlist.broker ul {
    padding-left: 0 !important;
    margin-left: 0 !important;
    text-align: center !important;
  }

  .brokers_list .agentlist li {
    margin-right: 0;
  }

  .account_settings .search_built select:last-of-type,
  .account_settings.saved_search input[type="text"] {
    margin-left: 0;
  }

  .searchform li input[type="text"],
  .searchform .search_built select:last-of-type {
    margin-left: 0;
  }

  .pagebody ol.cma_form {
    padding-left: 0;
  }

  .thelastname {
    display: block;
  }

  .detailform li:nth-child(2),
  .detailform li:nth-child(3) {
    width: 100%;
  }

  form li b {
    width: 100% !important;
  }

  footer .menu li {
    float: left;
    width: 100%;
    margin-right: 0;
  }

  #pagination_container {
    width: 100%;
  }

  .map_property {
    margin: 21px 5px;
  }

  .property-detail-sec {
    margin: 0 5px 30px;
  }

  .detailform ul {
    margin: 0px 4px;
  }

  .detail_features_container {
    margin: 0 5px;
  }
  footer .subdivisionlist li {
    float: none;
    width: 100%;
  }
  .applynow {
    padding: 13px 0 0 10px;
  }

  .home-links.social-media-links li {
    margin-bottom: 10px;
  }

  .left-section.header-center li {
    margin-right: 0;
    margin-bottom: 15px;
    display: block;
  }

  .homepage-banner-wrap .banner-heading p {
    display: none;
  }
  .homepage-banner-wrap .banner-menu {
    max-width: 330px;
  }
  .homepage-banner-wrap .banner-menu-button {
    width: 100%;
    padding: 11px 0px;
  }
  .homepage-banner-wrap .banner-searchbar button {
    display: inline-flex;
  }
  .homepage-banner-wrap .banner-heading h1 {
    margin-top: 20px;
    font-size: 4.5rem;
  }

  .numeric_pagination {
    display: none;
  }

  #upper-pagination_container .page_links_container {
    display: flex;
    justify-content: center;
  }
}
