/*
 Theme Name: Theme 39
 Version: 1.0
 Author: Hardeep Kumar
Last Modified: July 30, 2020
*/

/********************************************
Card view
 ********************************************/
.card-view {
  margin-top: 30px;
}

.card-view .fplist-title {
  font-weight: 400;
  padding: 15px;
  background: var(--content-inverted-bg);
  border: 1px solid var(--content-light-bg);
  text-transform: none;
  margin: 0px 15px 15px;
  text-align: left;
}

.card-view .alt_property_container {
  background: #eee;
  box-shadow: 0px 3px 9.7px 0.3px rgba(0, 0, 0, 0.25);
  width: 264px;
  display: inline-block;
  position: relative;
  margin: 20px 15px 50px;
  padding: 0px;
}

.card-view .alt_property a {
  color: var(--content-link-color);
}

.card-view .alt_property a:hover {
  color: var(--content-link-hover);
}

.card-view .alt_propimage,
.card-view .alt_propimage img {
  width: 264px;
  height: 210px;
}

.card-view .search_results .alt_propimage img {
  width: 264px;
  height: 210px;
  border-bottom: 1px solid var(--grey-light);
}

.card-view .alt_price {
  float: none;
  position: absolute;
  display: inline-block;
  background-color: var(--content-inverted-bg);
  height: 30px;
  line-height: 30px;
  padding: 0px 8px;
  font-weight: bold;
  top: -220px;
  right: 10px;
  transition: top 0.5s ease;
}

.card-view .alt_title {
  float: none;
  width: 100%;
  padding-right: 0;
}

.card-view .alt_property .right_side {
  background: rgba(238, 238, 238, 0.8);
  width: 100%;
  position: relative;
  top: 0px;
  height: 165px;
  border-top: 1px solid var(--grey-light);
  transition: top 0.5s ease;
}

.card-view .alt_property .right_side p {
  margin: 0;
}

.card-view .alt_address {
  display: block;
  font-weight: bold;
  height: 37px;
  line-height: 37px;
  border-bottom: 1px solid var(--grey-light);
  width: 100%;
  padding: 0px 13px 0px 35px;
  box-sizing: border-box;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAPCAQAAACYNP27AAAAsUlEQVR4AV3Os0IoABQG4O96u7bZHpawZ9eSe5KmNOUeoAdIa71GNrfs6WTd/9gHSNBhR9jR7h9AthPh2LrjC5sFX+0JTZ7imRZhzxd6hWY8kO4BWoUuthx66KVJYdwLjxzaJMyjWFxQIRYEx7bx5+rsL2w7ZFioRIoGSagSBikXthUAii7+L+OJGSGM6DN64U16DGXiHpUADN1JDrjBe+tXyTXv3EHGVTrNf6i3qhbgFJ2LVDnD1uvrAAAAAElFTkSuQmCC') no-repeat 15px 10px;
}

.card-view .alt_details {
  width: 100%;
  border-bottom: 1px solid var(--grey-light);
  padding: 13px !important;
  box-sizing: border-box;
}

.card-view .alt_details li.alt_beds {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPCAQAAAChU0zgAAAAo0lEQVR4AY3SpUEFUACG0UNmDubApeEkNOMwAzswwIs4zIBbwl0a7nov2p5+p/7xZ12FHItacp/WyrEHmy4FwYMheTL0ZkeHmm9dPg1nnk5o+p1WmBMNptCnAG48+RAEUbAvpBBFTUSNgDOjUpdvxpO/PThPO6VKJCoBHEtIV5HIi4TBX9GpwTQSXrgR/u05E9K6IWqUvUYx1w+0iCwrl71yy18dD2EiMmjH6gAAAABJRU5ErkJggg==') no-repeat 0px 0px;
}

.card-view .alt_details li.alt_baths {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPCAQAAAChU0zgAAAAuklEQVR4AZ3KM2IkAABA0be2m7XdrKNyyrlADpDDBO1MFaOL7dSx0cU3iG072ffb74A4PboEnemdIf8E9cuR5IFT/NYO8qwa98wpfmoHKUZ85+yVx55z9nqKy/7Le83gpZjNXjqeGCUWZMnSa3KzXlmbpYqBqwAyZCr2AE2WwFU3sOqeDF8AoEbY8cKqHfDEgH+O+mfAEw4KScDlA5EkBFwFQIYKsaatArjkjluCjhHQ4LsPdvquQQBgDXOZLj9/97nSAAAAAElFTkSuQmCC') no-repeat 0px 0px;
}

.card-view .alt_details li.alt_sqft,
.card-view .alt_details li.alt_acreage {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPCAQAAAChU0zgAAAA20lEQVR4AY3SA4wXUBzA8U+2MbOGNMd5y/NqyrPykI2pZi/XrGx3nnF2Z3s6429/n/XjE4tsrUoDpU6HUuVKJeSV/ZYHyjXdllujXVq2uYXZSmTI8lgD9rroiiu+25Tu6pCPvvjhvfkAdniIZbFXK8wFzLfIfMxwRVWiqwMqbMF29ZocxGrNshJLXW825nmhwHxMd1ZuoqvFZgLueQM47DcoEkWp5YAHPgAOqHDCKaPpr+7SJ1eO56IoMQdwX9iAfxLQbm0g84/lBGbnFUlAifLAf2rSFpjVeQbAGLhrSBDcZeUdAAAAAElFTkSuQmCC') no-repeat 0px 0px;
}

.card-view ol.alt_details.alt_col1 {
  min-height: 130px;
}

.card-view .alt_col1 li {
  margin-bottom: 8px;
  padding-left: 33px;
  line-height: 16px;
}

.card-view ol.alt_details.alt_col2 {
  min-height: 120px;
  display: none;
}

.card-view .alt_col2,
.card-view .alt_col2 a {
  color: #b2b2b2;
  line-height: 18px;
}

.card-view .more_info_container {
  height: 40px;
  line-height: 40px;
  padding: 0px 13px;
}

.card-view .more_info_container a {
  text-decoration: underline;
}

.card-view .alt_property_container .like-flag {
  position: absolute;
  bottom: -30px;
  right: 9px;
  transition: bottom 0.5s ease;
  width: 20px;
}

.card-view .alt_property_container .like-flag a {
  text-indent: 0;
}

.card-view .alt_property_container:hover .like-flag {
  bottom: -155px;
}

.card-view .question_link a {
  background-color: #808080;
  position: absolute;
  bottom: -70px;
  right: 10px;
  width: 145px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: var(--content-inverted);
  text-decoration: underline;
  transition: bottom 0.5s ease;
}

.card-view p.description.alt_description {
  padding: 0px 8px 0px 13px;
  border: none;
  max-height: 0px;
  min-height: 0px;
  box-sizing: border-box;
  transition: max-height 0.5s ease, min-height 0.5s ease, padding 0.5s ease;
}

.card-view .alt_title .propflags.default_propflags {
  display: block;
  position: absolute;
  width: 25px;
  right: 10px;
  top: 50px;
  transition: top 0.5s ease;
}

.card-view .pricedrop,
.card-view .alt_pricedrop,
.card-view .green,
.card-view .alt_green,
.card-view .adult,
.card-view .alt_adult,
.card-view .virtualtour,
.card-view .alt_virtualtour,
.card-view .foreclosure,
.card-view .alt_foreclosure,
.card-view .shortsale,
.card-view .alt_shortsale,
.card-view .bidx_pricedrop,
.card-view .bidx_green,
.card-view .bidx_adult,
.card-view .bidx_virtualtour,
.card-view .bidx_foreclosure,
.card-view .bidx_shortsale,
.card-view .view_similar,
.card-view .alt_view_similar {
  margin: 0px 0px 5px;
}

.card-view .alt_property_container:hover .alt_property .right_side {
  top: -125px;
}

.card-view .alt_property_container:hover p.description.alt_description {
  padding: 13px 8px 13px 13px;
  border-bottom: 1px solid var(--grey-light);
  max-height: 125px;
  min-height: 125px;
}

.card-view .alt_property_container:hover .alt_price {
  top: -95px;
}

.card-view
  .alt_property_container:hover
  .alt_title
  .propflags.default_propflags {
  top: 175px;
}

.card-view .alt_property_container:hover .question_link a {
  bottom: -195px;
}

/********************************************
Layout Switch
********************************************/
.sort_form {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.view_option {
  display: inline-block;
  border: 1px solid #808080;
  padding: 5px 5px 0;
  position: relative;
  top: -4px;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -ms-transform: scale(0.8);
}

.view1 {
  float: left;
  margin-right: 10px;
  cursor: pointer;
}

.view2 {
  float: left;
  cursor: pointer;
}

.view1:hover #minimalist_view,
.view2:hover #full_view {
  fill: #9e9e9e;
}

.view1:active #minimalist_view,
.view2:active #full_view,
.view1.active #minimalist_view,
.view2.active #full_view {
  fill: var(--content-highlight);
}

/********************************************
search results card view
********************************************/
.search_results.card-view .alt_property_container {
  width: 310px;
  margin: 20px 45px 50px 10px;
}

.search_results.card-view .alt_propimage,
.search_results.card-view .alt_propimage img {
  width: 310px;
  height: 210px;
}

/*********************************************
Property Flags
**********************************************/
.like_login,
.like_on,
.like_off {
  display: inline-block;
  line-height: 24px;
  margin-left: 0;
}

.alt_propimage {
  margin-right: 10px;
}

.pricedrop,
.alt_pricedrop {
  text-indent: -9999px;
}

.propholder .propflags.default_propflags {
  margin-right: 10px;
}

.prop.co_commn {
  margin-left: calc(30% + 25px);
  float: left;
  width: 60%;
}
