/********************************************
 Theme Name: Theme 39
 Version: 1.0
 Author: Hardeep Kumar
Last Modified: Alok Jain, September 30, 2020
 ********************************************/

a {
	color: var(--content-link-color);
	text-decoration: none;
}
a:hover {
	color: var(--content-link-hover);
}
.account-dashboard a {
	color: var(--content-link-color);
}
.account-dashboard a:hover {
	color: var(--content-link-hover);
}
.pagebody table {
	border: 1px solid var(--border);
}
.pagebody table td {
	border: 1px solid var(--border);
	padding: 2px;
}
em {
	font-style: italic;
}
b,
strong {
	font-weight: 700;
}
select {
	-webkit-appearance: none; /*Removes default chrome and safari style*/
	-moz-appearance: none; /*Removes default style Firefox*/
	background-image: url(/templates/template_39/images/down-arrow.png);
	background-color: var(--content-inverted);
	background-position: calc(100% - 10px) 11px;
	background-repeat: no-repeat;
}
select::-ms-expand {
	display: none;
}
/********************************************
  Common Layout
  ********************************************/
main {
	overflow: hidden;
}
body {
	background: var(--content-inverted);
	font-family: var(--content-font);
	font-size: var(--content-font-size);
	color: var(--content-default);
}
.container {
	width: 100%;
	margin: 0 auto;
	position: relative;
	box-sizing: border-box;
}
.container:after {
	visibility: hidden;
	display: block;
	content: "";
	clear: both;
	height: 0;
}
.header-bar {
	display: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.menu-title,
.fplist-title,
.search-title,
.featured_listings_header,
.detail-image-title,
.detailfeatures .detail-features-title,
.detail-comment-title,
.detail-column-title,
.detail-contact-title,
.detail-social-title,
.social-title,
.comment_title,
.subhead_agentlist,
.sd_addressbar,
.column-title,
.testimonial_subtitle,
.agent_listings_header,
.notice .subhead,
.mtg_title,
.notice_title,
.liked-list-title,
.featuredprop_title,
.refine-title,
.detail-features-similarprops,
.notice-title, .board_title,
.buyer_agent_comm_title{
	font-family: var(--heading-font);
	text-transform: uppercase;
	font-size: var(--h1-font-size);
	font-weight: 700;
	margin-bottom: 15px;
	border-bottom: 1px solid var(--grey-bg);
	padding-bottom: 4px;
	color: var(--heading-color);
}

.buyer_agent_comm_block{
	margin-right: 3%;
}
.subdivision-list-title,
.condominium-list-title {
	font-family: var(--heading-font);
	text-transform: uppercase;
	font-size: var(--h1-font-size);
	font-weight: 700;
	margin-bottom: 15px;
	border-bottom: 1px solid var(--grey-bg);
	padding-bottom: 4px;
}
h1{
	font-size: var(--h1-font-size);
}
h2, .board_title{
	font-size: var(--h2-font-size);
}
h3{
	font-size: var(--h3-font-size);
}
.refine-title {
	text-align: center;
}
.social-title {
	width: 100%;
	display: flex;
	justify-content: center;
}
.blog {
	border-bottom: 1px solid var(--grey-light);
	padding-bottom: 5px;
}
.blog .blog {
	border-bottom: 0px;
	padding-bottom: 0;
}
.blog_title {
	border-bottom: none;
}
.blog .blog .blog-title {
	font-size: var(--h1-font-size);
	font-family: var(--heading-font);
	color: var(--heading-color);
	margin: 5px 0;
}
.detail-mapview-title,
.detail-streetview-title {
	font-family: var(--heading-font);
	font-size: var(--content-font-size);
	font-weight: 700;
	margin-bottom: 5px;
	margin-top: 8px;
}
form .note,
form h2,
.mtg_title {
	margin-top: 10px;
}
.search-title {
	margin-top: 5px;
}
.centerbar p {
	margin-bottom: 15px;
	margin-top: 0;
}
.article {
	float: left;
	width: 78%;
	padding-right: 10px;
	margin-left: 30px;
	box-sizing: border-box;
}
aside {
	float: left;
	width: 18%;
	border-left: 1px solid var(--grey);
	position: relative;
	left: -1px;
	padding-left: 10px;
	box-sizing: border-box;
}
header {
	width: 100%;
	background-size: cover;
}
#menu_section {
	width: 100%;
	font-size: 1.6rem;
	padding: 0 10px;
	box-sizing: border-box;
}
#pre-header {
	overflow: hidden;
}
.logo {
	width: 175px;
	margin-top: 5px;
	text-align: center;
	float: left;
}
.logo .tagline {
	margin-top: 5px;
	display: block;
}
.social-media-links {
	display: block;
	padding: 10px 20px;
}
.social-media-links span.likepage {
	display: block;
	font-size: 1.4rem;
	margin-bottom: 15px;
}
.social-media-links ul {
	margin: 0;
	padding-left: 0;
}
.fb_share_count {
	margin-top: 15px;
}
.home-links.social-media-links {
	padding: 20px 0 0;
	overflow: hidden;
	float: right;
	width: 420px;
	text-align: center;
}
.social-media-links li {
	list-style: none;
	display: inline-block;
	vertical-align: top;
	margin-left: 18px;
}
.social-media-links .fa {
	font-size: 3rem;
	color: var(--content-default);
}
.social-media-links li:hover .fa {
	color: var(--content-highlight);
}
.left-section.header-center {
	position: static;
	display: inline-block;
	float: none;
	width: calc(100% - 650px);
	text-align: right;
	margin-top: 20px;
}
.left-section.header-center li a:hover {
	background-color: rgba(0, 0, 0, 0.761);
	color: var(--content-inverted);
}
.nav-sec .nav-list {
	background-color: #808080;
	height: 35px;
	margin-top: 5px;
	border: 1px solid #efefef;
}
.nav-sec .nav-list li {
	display: inline-block;
	position: relative;
}
.nav-sec .nav-list li a {
	font-size: 1.4rem;
	font-weight: 600;
	color: var(--content-inverted);
	border-right: 1px solid var(--content-inverted);
	padding: 0 16px;
	display: block;
	margin: 10px 0px;
	white-space: nowrap;
}

.nav-sec .nav-list li.nav-wrap a {
	border-right: none;
}

.nav-sec .nav-list li a:hover {
	color: var(--content-inverted-bg);
}
.nav-sec .nav-list li.nav-wrap > a {
	padding-right: 20px;
}
.nav-sec .nav-list li.nav-wrap > a:after {
	background: transparent
		url("/templates/template_39/images/down-arrow_white.png") no-repeat;
	position: absolute;
	content: "";
	width: 6px;
	height: 6px;
	top: 15px;
	right: 10px;
}
.nav-sec .nav-list li:hover ul,
.nav-sec .nav-list li:focus-within ul {
	background: #808080;
	box-shadow: 0px 0px 0.98px 0.02px rgba(0, 0, 0, 0.25);
	z-index: 4;
	padding: 0;
	min-width: 150px;
}

.nav-sec .nav-list li ul {
	position: absolute;
	right: 0;
	display: none;
}

.nav-sec .nav-list li:hover ul {
	display: block;
}

.nav-sec .nav-list li:hover ul li,
.nav-sec .nav-list li:focus-within ul li {
	border-bottom: 1px solid var(--content-inverted);
	display: block;
}
.nav-sec .nav-list li:hover ul li a,
.nav-sec .nav-list li:focus-within ul li a {
	border: none;
}
/********************************************
  main header
  ********************************************/
#banner {
	width: calc(100% - 20px);
	background: url(../images/banner-bg.jpg) no-repeat center;
	background-size: cover;
	margin: 0 10px 4px;
	overflow: hidden;
	position: relative;
	height: 250px;
}
#middle-section ul {
	padding-left: 0;
}
#middle-section li {
	display: block;
	background: rgba(0, 0, 0, 0.5);
	box-sizing: border-box;
	margin-bottom: 10px;
	text-align: center;
	height: 116px;
	line-height: 116px;
}
#middle-section li a {
	color: var(--content-inverted);
	font-size: 3rem;
	display: block;
}
.left-section {
	float: left;
	vertical-align: bottom;
	position: absolute;
	bottom: 5px;
}
.left-section li {
	box-shadow: 0px 0px 4.85px 0.15px rgba(225, 255, 255, 1);
	display: inline-block;
	font-weight: 600;
	font-size: 1.8rem;
	margin-right: 15px;
}
.left-section li a {
	color: var(--content-inverted);
	display: block;
	height: 48px;
	line-height: 48px;
	padding: 0 15px;
	background-color: rgba(128, 128, 128, 0.761);
}
.left-section li a:hover {
	background-color: rgba(255, 255, 255, 0.761);
	color: var(--content-default);
}
.home-page-list-group {
	display: none;
}
.testimonial-main {
	float: left;
	width: 100%;
	background: rgba(0, 0, 0, 0.3);
	padding: 0px 20px;
	box-sizing: border-box;
	color: var(--content-inverted);
	font-size: 1.3rem;
	line-height: 24px;
}
.testimonial-main .caroufredsel_wrapper {
	height: 80px !important;
}
.testimonial-main .testimonial {
	border-bottom: none;
}
.testimonial-main .testi_title {
	text-transform: uppercase;
	font-size: 1.4rem;
	font-weight: 700;
	display: block;
}
.testimonial-main .testimonial_title,
.testimonial-main .testimonial_date {
	display: none;
}
.testimonial-main .testimonial_body {
	font-style: italic;
	text-align: center;
}
.testimonial-main .testimonial_name {
	display: block;
	color: #c0c0c0;
}
.testimonial-main .testimonial-pg {
	color: var(--content-inverted);
	float: right;
	margin-top: 5px;
}
.testimonial-main .testimonial-pg:hover {
	color: var(--content-inverted) !important;
}

/********************************************
  Home page
  ********************************************/
#content {
	margin-bottom: 60px;
}
.fplist {
	margin-bottom: 50px;
	overflow: hidden;
	text-align: center;
}
/********************************************
  Breadcrumb
  ********************************************/
#breadcrumbs {
	width: 99%;
	margin: 0 auto;
}
.breadcrumb {
	border-bottom: 1px solid var(--content-default);
	padding-bottom: 10px;
	overflow: hidden;
	margin: 10px 0px;
}
.breadcrumb ol {
	margin: 0 20px;
	overflow: hidden;
}
.breadcrumb li {
	float: left;
	margin-right: 10px;
	line-height: 22px;
	background: url(/templates/template_39/images/breadcrumbs-arrow.png) no-repeat
		left center;
	padding-left: 15px;
}
.breadcrumb li:first-child {
	background: none;
	padding-left: 0;
}
/********************************************
  Centerbar
  ********************************************/
.centerbar {
	float: left;
	width: 78%;
	padding-right: 10px;
	margin-left: 30px;
}
.centerbar form li {
	margin-bottom: 10px;
}

.contactform_thename,
.contactform_thename {
	width: 156px !important;
	margin-left: 5px;
}
.centerbar ul.form-submit-button li,
.centerbar .contact_form.contact_form input.submit {
	margin-bottom: 0;
}
/********************************************
  list margin remove
  ********************************************/
.pagination ol,
.widget-body ol,
.widget-inner ul,
.promo_form ol {
	padding-left: 0px;
}
#promo-contact-form {
	width: auto;
}
/********************************************
  pagination
  ********************************************/
#pagination_container {
	display: flex;
	justify-content: space-between;
}
#pagination_container span {
	margin: 0 2px;
}
#pagination_container form.page_link,
#pagination_container .results_returns {
	display: inline-block;
	margin: 0 2px;
}
#pagination_container .results_returns {
	margin-right: 5px;
}
#pagination_container select.page_select,
#pagination_container select.sort_select {
	width: 55px;
	padding: 0 5px;
}
#pagination_container .page_link select.page_select {
	width: 100%;
}
.next_container .next_arrow,
.prev_container .prev_arrow {
	text-indent: -9999px;
	color: var(--content-default);
	display: inline-block;
}
.prev_container,
.next_container {
	display: inline-block;
	width: 10px;
	height: 23px;
}
.prev_container {
	background: url(/templates/template_39/images/carousel-arrow_black.png)
		no-repeat center;
	margin-right: 15px;
}
.next_container {
	background: url(/templates/template_39/images/carousel-arrow-right_black.png)
		no-repeat center;
	margin-left: 15px;
}
#detailform
	.contact-form-request-container
	.form-request-elements
	li:last-child {
	clear: both;
}
#detailform.scrolled {
	position: fixed;
	top: 0;
	right: 30px;
}
.page_returns_select,
.page_select {
	width: 60px;
	height: 25px;
	line-height: 25px;
	background: var(--content-inverted) url(/templates/template_39/images/down-arrow.png) no-repeat
		calc(100% - 10px) 8px;
}
#lower-pagination_container {
	display: flex;
	justify-content: space-between;
}
#lower-pagination_container .results_returns {
	display: inline-block;
}
#lower-pagination_container .results_returns .sort_form {
	vertical-align: inherit;
	margin-left: 10px;
}
#lower-pagination_container .page_links_container {
	margin-top: 0;
}
.numeric_pagination {
	margin: 10px 0;
	align-items: center;
}
.numeric_pagination ol {
	border: 0;
}
.numeric_pagination li {
	border: 0;
	padding: 0;
	margin-right: 5px;
}
.numeric_pagination li a,
.numeric_pagination li.ellipsis {
	padding: 5px 10px;
	background-color: var(--grey-bg);
	display: inline-block;
	border: 1px solid #cecece;
}
.numeric_pagination li:last-child {
	margin-right: 0;
}
.numeric_pagination li a:hover {
	background-color: var(--content-highlight);
	color: var(--content-inverted);
}
.numeric_pagination li.bold {
	background-color: var(--content-default);
	color: var(--content-inverted);
	padding: 5px 10px;
	margin-right: 5px;
}
.numeric_pagination .next_container,
.numeric_pagination .prev_container {
	height: 25px;
}
#upper-pagination_container {
	margin-bottom: 15px;
}
#upper-pagination_container .page_links_container {
	display: none;
}
/********************************************
  Sidebar
  ********************************************/
.rightbar {
	border-left: 1px solid #666;
	float: left;
	width: 18%;
	position: relative;
	left: -1px;
	padding-left: 10px;
}
.refine_search,
#sidesearch {
	border-bottom: 1px solid var(--border);
	padding-bottom: 20px;
	margin-bottom: 20px;
}
#sidesearch li,
.refine_search li {
	margin-bottom: 10px;
}
#sidesearch input[type="text"],
#sidesearch select,
.refine_search select,
.refine_search input[type="text"] {
	width: 100%;
	float: none;
	box-sizing: border-box;
}
.refine_search .refine_submit {
	text-align: center;
}
.refine-price-from,
.refine-price-to {
	width: 48.5%;
	display: inline-block !important;
	margin-bottom: 0 !important;
}
.refine-price-to {
	margin-left: 1.5%;
}
.featuredprop {
	position: relative;
	max-width: 252px;
	width: 100%;
	margin: 0px auto 22px;
}
.featuredprop a {
	min-height: 190px !important;
	min-width: 250px !important;
	border: 1px solid var(--border);
	display: inline-block;
}
.featuredprop img {
	min-height: 190px !important;
	min-width: 250px !important;
	float: left;
}
.featured_footer {
	background: rgba(255, 255, 255, 0.878);
	color: var(--grey);
	position: absolute;
	z-index: 3;
	font-size: 1.5rem;
	text-align: left;
	line-height: 18px;
	padding: 6px 10px;
	top: 65px;
	left: 1px;
	text-transform: uppercase;
	width: 180px;
}
/********************************************
  fpmorelink
  ********************************************/
.fpmorelink {
	width: 100%;
	display: block;
	overflow: hidden;
	margin: 0 auto;
	text-align: center;
}
.fpmorelink a,
.likepage_link {
	border: none;
	background-color: var(--content-button-bg);
	width: 220px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	color: var(--content-button-text);
	display: inline-block;
	margin-bottom: 10px;
}
.fpmorelink a:hover,
.likepage_link:hover {
	background-color: var(--content-button-hover-bg);
	color: var(--content-button-hover-text);
}
/********************************************
  Like Listing
  ********************************************/
.user_liked_listing {
	width: 252px;
	margin: 0px auto;
}
.customer_likes {
	border: 0px;
}
.customer_prop {
	position: relative;
	border: 1px solid var(--border);
}
.saved_istings_container .right_side,
.lplist .right_side {
	margin-left: 15px;
}
.saved_istings_container ol,
.lplist ol {
	margin-left: 0px;
}
.saved_istings_container a,
.lplist a {
	color: var(--content-link-color);
}
.saved_istings_container .alt_title,
.saved_istings_container .alt_price,
.lplist .alt_title,
.lplist .alt_price {
	font-weight: 700;
}
.saved_istings_container .alt_property,
.lplist .alt_property {
	border-bottom: 1px solid #cecece;
	padding-bottom: 5px;
	margin-bottom: 20px;
}
/********************************************
  Footer
  ********************************************/
footer .menu li a,
footer .subdivisionlist li a {
	font-size: var(--content-font-size);
	line-height: 24px;
	color: var(--grey);
	font-family: var(--content-font);
	text-transform: uppercase;
	text-decoration: none;
}
footer {
	background: var(--grey);
	color: var(--content-inverted);
	padding: 19px 0px;
	font-size: 1.1rem;
	line-height: 16px;
	overflow: hidden;
}
footer a {
	color: var(--content-inverted);
	text-decoration: underline;
}
footer a:hover {
	color: var(--content-inverted-bg) !important;
	text-decoration: none;
}
.subscribing-brokerage-container {
	margin-left: 30px;
}
.footercopy {
	float: left;
	width: 700px;
	margin-left: 30px;
}
.board_footer,
.footer_copyright,
.footer_disclaimer {
	margin-bottom: 8px;
}
.brokeridxlink {
	float: right;
	text-align: center;
	margin-top: 44px;
	margin-right: 30px;
}
footer nav,
footer .subdivisionlist {
	overflow: hidden;
	margin: 0px 30px 20px;
}
footer .menu-title {
	display: none;
}
footer .menu,
footer .subdivisionlist {
	display: flex;
	gap: 4%;
}
footer .menu ul,
footer .subdivisionlist ol {
	flex: 1;
}
footer .menu li,
footer .subdivisionlist li {
	border-bottom: 1px solid var(--border);
	margin: 10px 0;
}
footer .menu li a,
footer .subdivisionlist li a {
	color: var(--content-inverted);
}
footer .condominium-list-title,
footer .subdivision-list-title {
	border: none;
	margin-bottom: 0px;
	padding-bottom: 0px;
}
/********************************************
  page body
  ********************************************/
.pagebody,
.search-form-container {
	background: var(--grey-bg);
	border-bottom: 2px solid var(--border);
	width: 100%;
	clear: both;
	font-size: 1.3rem;
	line-height: 22px;
	padding: 10px;
	box-sizing: border-box;
	margin-bottom: 25px;
	overflow: hidden;
}
#searchform {
	padding-bottom: 60px;
}
#fb_login_form {
	text-align: center;
}
#fb_login_form .fbLogin {
	margin: 0 auto;
}
#login-form {
	max-width: 500px;
	margin: 0 auto;
	overflow: hidden;
	text-align: left;
}
.pagebody #fb_login_form label {
	width: 30%;
}
.pagebody #login-form input[type="text"],
.pagebody #login-form input[type="password"] {
	width: 70%;
}
.pagebody #login-form .login_button {
	float: right;
}
/********************************************
  Propholder
  ********************************************/
.propholder {
	background: var(--grey-bg);
	border-bottom: 2px solid var(--border);
	width: 100%;
	clear: both;
	font-size: 1.3rem;
	line-height: 22px;
	padding: 10px;
	box-sizing: border-box;
	margin-bottom: 25px;
	overflow: hidden;
}
.propholder:last-of-type {
	border-bottom: 0;
	padding-bottom: 0;
}
.propfooter {
	text-align: center;
}
.propfooter .propcontact {
	font-weight: 700;
}
/********************************************
  Form Styling
  ********************************************/
form p {
	margin-bottom: 9px;
}
.text,
input[type="text"],
input[type="email"],
input[type="phone"],
input[type="tel"],
input[type="number"],
input[type="password"],
select,
textarea {
	font-size: var(--content-font-size);
	font-family: var(--content-font);
	font-weight: 400;
	border: 1px solid #666;
	color: var(--grey);
	height: 32px;
	line-height: 32px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
	padding: 0px 5px;
	background-color: var(--content-inverted);
}
input:focus,
textarea:focus,
select:focus {
	border: 1px solid var(--content-highlight) !important;
}
textarea {
	min-height: 120px;
	line-height: 15px;
	padding: 5px !important;
}
.promo_form.contact_form input[type="submit"].submit, #contact_form.contact_form input[type="submit"].submit,
.the_contact_form.contact_form input[type="submit"].submit,
.contact_form input[type="submit"].submit,
.the_contact_form.pop-up input[type="submit"].submit,
.detailform.pop-up input[type="submit"].submit,
.the_contact_form input[type="submit"].submit,
input[type="submit"],
input[type="button"],
input[type="reset"],
.detail-next-listing,
.detail-previous-listing,
.save-search a,
.sb_poll_rate,
.page_links_submit,
button {
	display: inline-block;
	font-size: 1.5rem;
	line-height: 24px;
	padding: 5px 15px;
	text-transform: uppercase;
	cursor: pointer;
	color: var(--content-button-text);
	background: var(--content-button-bg);
	border: 1px solid var(--content-button-bg);
	margin-top: 5px;
	border-radius: 0;
}
.detail_pagination .detail-next-listing,
.detail_pagination .detail-previous-listing {
	margin: 0px 30px 0px 30px;
}
.promo_form.contact_form input[type="submit"].submit:hover,
#contact_form.contact_form input[type="submit"].submit:hover,
.the_contact_form.contact_form input[type="submit"].submit:hover,
.contact_form input[type="submit"].submit:hover,
.the_contact_form.pop-up input[type="submit"].submit:hover,
.detailform.pop-up input[type="submit"].submit:hover,
.the_contact_form input[type="submit"].submit:hover,
input[type="submit"]:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
.detail-next-listing:hover,
.detail-previous-listing:hover,
.save-search a:hover,
.sb_poll_rate:hover,
.page_links_submit:hover,
button:hover {
	background: var(--content-button-hover-bg);
	border: 1px solid var(--content-button-hover-bg);
	color: var(--content-button-hover-text);
}
input[type="submit"]:focus,
.page_links_submit:focus {
	border: 1px solid var(--content-highlight) !important;
}
button.page_links_submit {
	margin-top: 0;
	padding: 0px 5px;
}
.save-search {
	text-align: center;
}
.save-search a:hover {
	color: var(--content-inverted);
}
.save-search a {
	height: auto;
}
.detail_pagination {
	width: 100%;
	overflow: hidden;
}
.detail-next-listing {
	float: right;
}
.profile_spouse {
	margin-bottom: 5px;
}
.the_contact_form.contact_form {
	margin-top: 5px;
	margin-bottom: 20px;
}
/********************************************
  Mortgage calculator
  ********************************************/
#detailform li b,
#commentform b {
	width: 125px !important;
	display: block;
	float: left;
	line-height: 21px;
	margin-top: 10px;
	font-weight: 700;
}
input[type="text"].form-control-plaintext {
	border: none;
	height: auto;
	background: none;
}
.mtg_output label {
	width: auto ;
	margin-top: 3px !important;
}

/********************************************
  cma request
  ********************************************/
.cma_form_container {
	margin-bottom: 15px;
}
.cma_form_container input[type="text"],
.cma_form_container input[type="email"],
.cma_form_container input[type="tel"],
.cma_form_container select {
	width: 200px;
}

/********************************************
  Agents Page
  ********************************************/
.agentlist li img {
	border: 1px solid var(--border);
	max-height: none;
}
.fplist.agentlist .alt_property_container {
	display: block;
	margin-bottom: 10px;
	padding: 10px 5px;
	text-align: left;
	border-bottom: 2px solid var(--border);
}
.agent-testimonial-title {
	font-weight: bold;
	margin: 20px 0;
	padding-bottom: 5px;
	border-bottom: 1px solid var(--border);
	text-transform: uppercase;
}
/********************************************
  Social
  ********************************************/
.social li {
	height: 38px;
	overflow: hidden;
	float: none;
}
.property_apply .social a,
.social_icons .social a {
	position: relative;
}
.property_apply .social li img,
.social_icons .social li img {
	position: relative;
	top: 0;
	width: auto;
	height: auto;
	padding-left: 0;
}
.property_apply .social a:hover img,
.social_icons .social a:hover img {
	position: relative;
	top: -38px;
}
/********************************************
  Listing
  ********************************************/
.sd_addressbar {
	box-sizing: border-box;
	line-height: 30px;
	padding-left: 5px;
}
/********************************************
  Poll
  ********************************************/
.polllist_row:hover {
	background: var(--content-button-bg) !important;
}
.polllist_row:hover td a {
	color: var(--content-button-text) !important;
}
.polllist_header,
.polllist_row {
	border-bottom: 1px solid var(--border);
}
.pollcss .propholder,
.pollcss .alt_property_container,
.poll-listing-container,
.poll_listing_container,
.pollcss .alt_property_container {
	border: 0;
	border-bottom: 2px solid var(--border);
}
.checkboxval {
	border: 2px solid var(--border);
}
.poll-container table,
.poll-container table td,
.polllist,
.polllist td {
	border: none;
}
/***********************************
  Running Poll Page
  ************************************/
.poll-rating .rate2 {
	font-size: 2.7rem;
	padding: 7px 0;
	height: 20px !important;
}
/********************************************
  Testimonials
  ********************************************/
.testimonial {
	border-bottom: 1px solid var(--border);
	padding-bottom: 5px;
}
.testimonial-list-container .testimonial {
	width: 300px;
	float: left;
	text-align: center;
}
.testimonial .testimonial_title {
	text-align: left !important;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 1.5rem;
	display: inline-block;
	color: var(--heading-color);
	font-family: var(--heading-font);
}
.testimonial .testimonial_date {
	float: right;
}
.testimonial .testimonial_agent {
	overflow: hidden;
}
.testimonials-page-request,
.testimonials-wrapper .notice {
	background: var(--content-inverted);
	margin: -10px -10px 0;
	padding: 10px 10px 30px;
}
.testimonials-page-request {
	margin-bottom: 20px;
	border-bottom: none;
	font-size: 1.6rem;
}
#foo2_pag {
	margin: 10px 0 0;
	text-align: center;
}
#foo2_pag span {
	text-indent: -9999px;
	display: block;
}
#foo2_pag a {
	display: inline-block;
	width: 10px;
	height: 10px;
	background: var(--content-inverted);
	border-radius: 50%;
	margin: 5px;
}
.testimonial-list-container .testimonial {
	display: none;
}
.testimonial-pg {
	text-align: center;
	display: inline-block;
	color: var(--content-inverted);
	text-decoration: underline;
}
#foo2_pag a.selected {
	background: var(--content-highlight);
}
.testimonial-pg:hover {
	color: var(--content-highlight);
}
.testimonial_form {
	max-width: 700px;
}
.testimonial_form .page-title {
	text-align: center;
}
.testimonial_form .btn {
	float: right;
}
/********************************************
  Search Results
  ********************************************/
.propholder .propcomment {
	margin: 10px;
}
.propimage {
	margin-left: 10px;
}
.prop {
	margin-right: 15px;
}

.propfooter {
	margin-bottom: 5px;
}
.row-view .alt_property,
.contactform .alt_property {
	background: var(--grey-bg);
	border-bottom: 2px solid var(--border);
	width: 100%;
	clear: both;
	font-size: 1.3rem;
	line-height: 22px;
	padding: 10px;
	box-sizing: border-box;
	margin-bottom: 25px;
	overflow: hidden;
}
.row-view .alt_propimage img,
.contactform .alt_propimage img {
	border: 1px solid var(--border);
}
.row-view .alt_title,
.contactform .alt_title {
	margin-bottom: 10px;
	font-weight: 700;
	border-bottom: 1px solid var(--border);
	padding-bottom: 5px;
	margin-top: 0;
}
.row-view .description.alt_description,
.contactform .description.alt_description {
	margin-bottom: 10px;
}
.row-view .alt_price,
.contactform .alt_price {
	font-weight: 700;
}
.row-view a,
.contactform .alt_price a {
	color: var(--content-link-color);
}
.row-view a:hover,
.contactform .alt_price a:hover {
	color: var(--content-link-hover);
}
.row-view .alt_details,
.contactform .alt_details {
	line-height: 17px;
}
.row-view .right_side,
.contactform .right_side {
	margin-left: 10px;
	margin-bottom: 10px;
}
.searchitems {
	overflow: hidden;
	display: flex;
	line-height: 20px;
}
.searchitems h2 {
	float: left;
	margin-right: 15px;
	border-bottom: 0px;
	margin-top: 0;
}
.searchitems .notice_title {
	float: left;
	border-bottom: 0px;
	margin-right: 10px;
}
.searchitems ol {
	display: inline-block;
	margin-left: 5px;
}
.searchitems li {
	float: left;
	margin-right: 5px;
	line-height: 20px;
}
/********************************************
  Login Popup
  ********************************************/
.popupform input[type="submit"]:hover {
	border: none;
}
.widget-link.agent-widget-link span {
	text-decoration: none;
}
/********************************************
  Mobile Navigation
  *********************************************/
.mobile-nav,
.slicknav_menu,
.detail_comment.detailCommentprint,
.notes-print,
.prop_heading {
	display: none;
}
/*****************************************
	  Poll Page Social icons
	  *****************************************/
.share-url .social .social-title {
	text-align: center;
}
.share-url .social {
	float: left;
	width: 100%;
	box-sizing: border-box;
}
.social ul {
	padding: 0 5px;
}
.social li img {
	display: block;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 32px;
	height: 38px;
	padding-left: 32px;
}
.social li:hover img {
	top: 0;
}
.social-facebook {
	background: url(../bttns/facebook.png) no-repeat;
}
.social li:hover .social-facebook {
	background: url(../bttns/facebook.png) no-repeat bottom;
}
.social-digg {
	background: url(../bttns/digg.png) no-repeat;
}
.social li:hover .social-digg {
	background: url(../bttns/digg.png) no-repeat bottom;
}
.social-delicious {
	background: url(../bttns/delicious.png) no-repeat;
}
.social li:hover .social-delicious {
	background: url(../bttns/delicious.png) no-repeat bottom;
}
.social-google {
	background: url(../bttns/googlebookmark.png) no-repeat;
}
.social li:hover .social-google {
	background: url(../bttns/googlebookmark.png) no-repeat bottom;
}
.social-linkedin {
	background: url(../bttns/linkedin.png) no-repeat;
}
.social li:hover .social-linkedin {
	background: url(../bttns/linkedin.png) no-repeat bottom;
}
.social-reddit {
	background: url(../bttns/reddit.png) no-repeat;
}
.social li:hover .social-reddit {
	background: url(../bttns/reddit.png) no-repeat bottom;
}
.social-stumbled,
.social-stumbledupon {
	background: url(../bttns/stumbleupon.png) no-repeat;
}
.social li:hover .social-stumbled,
.social li:hover .social-stumbledupon {
	background: url(../bttns/stumbleupon.png) no-repeat bottom;
}
.social-twitter {
	background: url(../bttns/twitter.png) no-repeat;
}
.social li:hover .social-twitter {
	background: url(../bttns/twitter.png) no-repeat bottom;
}
.social-bluesky {
	background: url(../bttns/bluesky.png) no-repeat;
}
.social li:hover .social-bluesky {
	background: url(../bttns/bluesky.png) no-repeat bottom;
}
.social-linktree {
	background: url(../bttns/linktree.png) no-repeat;
}
.social li:hover .social-linktree {
	background: url(../bttns/linktree.png) no-repeat bottom;
}
.social-myspace {
	background: url(../bttns/myspace.png) no-repeat;
}
.social li:hover .social-myspace {
	background: url(../bttns/myspace.png) no-repeat bottom;
}
.social-copy {
	margin-top: 5px;
}
.social li:hover .social-copy {
	background: url(../bttns/share-url.png) no-repeat bottom;
}
.pageholder {
	border: 1px solid transparent;
}
/**********************************************
	  CSS for Homepage Banner with Search Bar
  ***********************************************/
#homepage-banner {
	width: calc(100% - 20px);
	box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
	margin: 0 10px 4px;
	overflow: hidden;
	position: relative;
	height: 500px;
}
#homepage-banner.type_buyer {
	background-size: cover;
}
.homepage-banner-wrap .banner-heading {
	text-align: center;
	color: var(--content-inverted);
}
.homepage-banner-wrap .banner-heading h1 {
	margin-top: 75px;
	font-size: var(--banner-heading-font-size);
	color: var(--banner-heading-color);
	border-bottom: none;
	text-transform: none;
	padding: 0;
	margin-bottom: 0px;
	font-family: var(--heading-font);
}
.homepage-banner-wrap .banner-heading p {
	font-size: var(--banner-subheading-font-size);
	color: var(--banner-subheading-color);
	line-height: 40px;
	margin: 0px;
	font-family: var(--heading-font);
}
.homepage-banner-wrap .banner-menu {
	text-align: center;
	color: var(--content-inverted);
	max-width: 700px;
	margin: 40px auto;
	position: relative;
}
.homepage-banner-wrap .banner-menu-items {
	float: left;
	width: 100%;
}
.homepage-banner-wrap .banner-menu-button {
	float: left;
	width: 27%;
	margin: 2px;
	text-align: center;
	font-size: 1.6rem;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 2px;
	color: var(--content-inverted);
	padding: 20px;
	cursor: pointer;
	border-bottom: 5px solid transparent;
}
.homepage-banner-wrap .banner-menu-button:hover {
	background-color: var(--content-default);
	border-color: var(--content-inverted-bg);
}
.homepage-banner-wrap .active {
	background-color: var(--content-default);
	border-color: var(--content-inverted-bg);
}
.homepage-banner-wrap .banner-searchbar {
	display: table;
	width: 100%;
	margin: 10px 0px;
	float: left;
}
.homepage-banner-wrap .banner-searchbar input[type="text"] {
	width: 70%;
	height: 45px;
	border-radius: 2px;
	padding: 2px 10px;
}
.homepage-banner-wrap .banner-searchbar button {
	width: 29%;
	padding: 10px;
	color: var(--content-default);
	border: none;
	font-size: 1.8rem;
	border-radius: 2px;
	background-color: var(--content-inverted-bg);
}
.homepage-banner-wrap .banner-searchbar button:hover {
	background-color: var(--content-inverted-bg);
}
.slide {
	z-index: 1;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	height: 100%;
	transition: opacity 1s ease-in-out;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	opacity: 0;
}
.show {
	opacity: 1;
}
/********************************************
  agent information block css
  ********************************************/
#menu_section .header .agentheader {
	font-weight: bold;
	color: var(--content-default);
	padding-top: 10px;
	text-align: right;
}
#menu_section .header .agentheader img {
	float: left;
	margin-right: 10px;
	max-width: 150px;
	max-height: 120px;
}
#menu_section .header .agentheader .agentimage {
	display: inline-block;
	margin-left: 10px;
	border: 1px solid var(--content-inverted);
}
#menu_section .header .agentheader ol {
	font-size: 1.2rem;
}
#menu_section .header .agentheader ol {
	display: inline-block;
	margin-right: 10px !important;
}
#menu_section .header .agentheader li {
	list-style-type: none;
	margin-top: 2px;
}
#menu_section .header .agentname {
	font-size: 2rem;
	font-style: italic;
}
#menu_section .header .brokerage {
	font-size: 1.8rem;
}
.banner-testimonials {
	float: left;
	width: 100%;
	position: absolute;
	bottom: 0;
}
.banner-testimonials .testimonial_title,
.banner-testimonials .testimonial_date {
	display: block;
	float: none !important;
	text-align: center !important;
}
.banner-testimonials .testimonial_title a {
	color: var(--content-inverted);
}
.banner-testimonials .testimonial_title a:hover {
	color: var(--content-inverted-bg);
}
/********************************************
  New styling
  ********************************************/
.search-form-container li input[type="text"],
.search-form-container li select {
	width: 200px;
}
.search-form-container input.the_search.upper_bttn {
	float: right;
	margin-bottom: 0px;
}
.search-form-container input.the_search.lower_bttn {
	float: right;
	margin-top: 14px;
}
.saved_listings_container .like-flag,
.search_results.row-view .like-flag,
.poll-listing-container .like-flag {
	vertical-align: baseline;
}
.saved_listings_container .like-flag a,
.search_results.row-view .like-flag a,
.poll-listing-container .like-flag a,
span.like_text {
	width: 20px;
}
.the_contact_form.contact_form textarea,
.form-request-elements textarea {
	width: 100%;
}
.like-flag a {
	width: 20px;
	cursor: pointer;
	text-indent: 0;
}
/* new social icon in info header css by narinder part start */
.agentheader .social-media-link-name {
	display: none;
}
.agentheader .social-list-itmes .social-Facebook {
	color: #4267b2;
}
.agentheader .social-list-itmes .social-Twitter {
	color: #000000;
}
.agentheader .social-list-itmes .social-Bluesky {
	color: #0082f8;
}
.agentheader .social-list-itmes .social-Linktree {
	color: #00e76f;
}
.agentheader .social-list-itmes .social-Share {
	color: #ff9900;
}
.agentheader .social-list-itmes {
	font-size: 22px;
}
.agentheader .social-med-links {
	padding-left: 0px;
	text-align: right;
	margin-top: 2px;
}
.agentheader .social-med-links li {
	width: 36px;
}
.map-frontpage {
	margin-top: 30px;
}

.customform-data-container select {
	background-position: calc(100% - 10px) 15px;
}

/* Pocket Listings */
.widget-edit-form .display_inline_block li {
	float: left;
}

.widget-edit-form .input-radio label {
    margin: 0 5px;
}

#content .width-full textarea {
    width: 100%;
}

#content .widget-edit-form input, #content .widget-edit-form select, #content .widget-edit-form textarea {
	margin-bottom: 0 !important;
}

.widget-edit-form .input-radio div {
    width: 33%;
    min-height: auto;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding-bottom: 0;
}

.widget-edit-form .input-radio legend {
	font-weight: normal;
}

.customform-data-container select[multiple] {
	background: var(--content-inverted) !important;
}

.sidesearch-filter-list .home-page-list-group {
	display: none;
	position: absolute !important;
	width: 96%;
	height: 175px;
	margin-left: 0px;
}
.sidesearch-filter-list .home-page-list-group .home-page-list-group-item {
	padding: 5px !important;
	font-size: 11px;
	width: 100% !important;
	position: relative !important;
	text-align: left !important;
	margin-bottom: 0px !important;
}

.sidesearch-filter-list .home-page-list-group .home-page-list-group-item:hover {
	background-color: #ddd;
}

.widget {
	color: var(--content-default);
}
.widget .widget-title {
	color: var(--heading-color);
	font-family: var(--heading-font);
}
