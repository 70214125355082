 @font-face {
	font-family: 'Droid Sans';
	src: url('/templates/common/fonts/droidsans/droidsans-bold-webfont.woff2') format('woff2'),
	     url('/templates/common/fonts/droidsans/droidsans-bold-webfont.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Droid Sans';
	src: url('/templates/common/fonts/droidsans/droidsans-webfont.woff2') format('woff2'),
	     url('/templates/common/fonts/droidsans/droidsans-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
